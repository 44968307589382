import { sendGet, sendPost, IRequestResult, uploadLocal } from '../api/request';

const ENV =
    process.env.NODE_ENV === 'production'
        ? 'http://api-admin.aijijiang.com'
        : 'http://api-admin-test.aijijiang.com';

/**
 * 发送验证码
 */
export const apiSendCode = async <T>(phone: string): Promise<IRequestResult<T>> => {
    return sendPost<T>('/sms/sendcode', { phone: phone, type: 'login' });
};

/**
 * 登录
 */
export const apiLogin = async <T>(phone: string, code: string): Promise<IRequestResult<T>> => {
    return sendPost<T>('/user/login', { phone: phone, code: code, loginType: 'pc_phone' });
};

/**
 * 获取用户信息
 */
export const apiUserInfo = async <T>(): Promise<IRequestResult<T>> => {
    return sendGet<T>('/user/userInfo');
};

/**
 * 获取用户配置信息
 */
export const apiGetUserConfig = async <T>(): Promise<IRequestResult<T>> => {
    return sendGet<T>('/pc/userconfig/model', { userId: localStorage.getItem('userId') || '0' });
};

/**
 * 保存用户配置信息
 */
export const apiSaveUserConfig = async <T>(params: any): Promise<IRequestResult<T>> => {
    return sendPost<T>('/pc/userconfig/saveConfig', {
        ...params,
        userId: localStorage.getItem('userId') || '0',
    });
};

/**
 * 创建作品
 */
export const apiCreateWork = async <T>(params: any): Promise<IRequestResult<T>> => {
    return sendPost<T>('/pc/work/create', params);
};

/**
 * 给作品改名
 */
export const apiUpdateWorkTitle = async <T>(params: any): Promise<IRequestResult<T>> => {
    return sendPost<T>('/pc/work/updateTitle', params);
};

/**
 * 给作品改名
 */
export const apiGetWorkList = async <T>(pageNum: number, pageSize: number) => {
    const userId = localStorage.getItem('userId') || '0';
    return sendGet<T>('/pc/work/list', { userId: userId, pageNum, pageSize });
};

/**
 * 删除作品
 */
export const apiDeleteWork = async <T>(id: any): Promise<IRequestResult<T>> => {
    return sendGet<T>('/pc/work/delete', { id });
};

/**
 * 复制作品
 */
export const apiCopyWork = async <T>(params: any): Promise<IRequestResult<T>> => {
    return sendGet<T>('/pc/work/copy', params);
};
/**
 * 解析SRT
 */
export const apiParseSrt = async <T>(srtBase64: any): Promise<IRequestResult<T>> => {
    return sendPost<T>('/pc/work/parseSrt', { srt: srtBase64 });
};

/**
 * 删除角色
 */
export const apiDeleteUserRole = async <T>(id: number): Promise<IRequestResult<T>> => {
    return sendGet<T>('/pc/userrole/delete', { id });
};

/**
 * 获取角色列表
 */
export const apiUserRoleList = async <T>(
    official: boolean,
    type: string,
    tag: string | null | undefined
): Promise<IRequestResult<T>> => {
    const userId = localStorage.getItem('userId') || '0';
    return sendPost<T>('/pc/userrole/list2', {
        userId,
        official: official ? 1 : 0,
        type,
        tags: (tag && [tag]) || [],
    });
};

/**
 * 获取角色收藏列表
 */
export const apiUserRoleCollectList = async <T>(
    tag: string | null | undefined,
    type?: string
): Promise<IRequestResult<T>> => {
    return sendPost<T>('/pc/userrole/collectList2', { type, tags: (tag && [tag]) || [] });
};

/**
 * 预设收藏
 */
export const apiRoleCollect = async <T>(roleId: number): Promise<IRequestResult<T>> => {
    const userId = localStorage.getItem('userId') || '0';
    return sendPost<T>('/pc/userrole/collectRole', { userId, roleId });
};

/**
 * 预设取消收藏
 */
export const apiRoleUnCollect = async <T>(roleId: number): Promise<IRequestResult<T>> => {
    const userId = localStorage.getItem('userId') || '0';
    return sendPost<T>('/pc/userrole/unCollectRole', { userId, roleId });
};

/**
 * Vip兑换
 */
export const apiVipRedempt = async <T>(code: string): Promise<IRequestResult<T>> => {
    return sendPost<T>('/redemptioncode/redempt', { code });
};

/**
 * 获取上传凭证
 */
export const apiUploadToken = async <T>(): Promise<IRequestResult<T>> => {
    return sendGet<T>('/qiniu/token');
};

/**
 * 配音文字解析为SSML
 */
export const apiTTSPrepare = async <T>(
    textBase64: string,
    voice: string,
    emotion: string,
    speed: number,
    pitch: number,
    onTrial: boolean,
    endBreak: number
): Promise<IRequestResult<T>> => {
    return sendPost<T>('/tts/prepare', {
        text: textBase64,
        voice,
        emotion,
        speed,
        pitch,
        onTrial,
        endBreak,
    });
};

// tts完成
export const apiTTSFinish = async <T>(
    prepareId: number,
    success: boolean,
    failure?: string
): Promise<IRequestResult<T>> => {
    return sendPost<T>('/tts/finish', { prepareId, success, failureReason: failure });
};

// tts 信息
export const apiTTSBalance = async <T>(): Promise<IRequestResult<T>> => {
    return sendGet<T>('/tts/userBalance');
};

// 获取用户所有预设标签
export const apiAllRoleTags = async <T>(): Promise<IRequestResult<T>> => {
    const userId = localStorage.getItem('userId') || '0';
    return sendGet<T>('/pc/userrole/getAllRoleTags', { userId });
};

// 关键词设置
export const enterKeywordIncome = async <T>(param: any): Promise<IRequestResult<T>> => {
    return sendPost<T>(`${ENV}/keywordManager/enterKeywordIncome`, [
        { ...param },
    ]);
};
// 关键词批量导入
export const enterKeywordIncomeBatch = async <T>(
    param: any,
    file: any
): Promise<IRequestResult<T>> => {
    return uploadLocal<T>(`${ENV}/excel/syncImport`, file, { ...param });
};

// 关键词查询
export const queryKeywordIncome = async <T>(param: any): Promise<IRequestResult<T>> => {
    return sendPost<T>(`${ENV}/keywordManager/list`, { ...param });
};

// 关键词查询（短视频）
export const queryListMovieApplying = async <T>(param: any): Promise<IRequestResult<T>> => {
    return sendPost<T>(`${ENV}/keywordManager/listMovieApplying`, { ...param });
};

//小说平台列表查询
export const queryAllNovelPlatform = async <T>(param: any): Promise<IRequestResult<T>> => {
    // return sendPost<T>(`${ENV}/keywordManager/novelPlatform/all`, { ...param });
    return sendPost<T>(`${ENV}/keywordManager/novelPlatform/all`, { ...param });
};
// 修改状态（短视频）
export const updateMovieKeywordStatus = async <T>(param: any): Promise<IRequestResult<T>> => {
    return sendPost<T>(`${ENV}/keywordManager/updateMovieKeywordStatus`, { ...param });
};

// 提现列表查询
export const queryWithdrawal = async <T>(param: any): Promise<IRequestResult<T>> => {
    return sendPost<T>(`${ENV}/withdrawManager/list`, { ...param });
};
// 确认提现
export const confirmWithdrawal = async <T>(param: any): Promise<IRequestResult<T>> => {
    return sendPost<T>(`${ENV}/withdrawManager/confirm`, { ...param });
};
// 提现详情
export const withdrawalDetail = async <T>(param: any): Promise<IRequestResult<T>> => {
    return sendGet<T>(`${ENV}/withdrawManager/detail`, { ...param });
};

// 提现列表导出
export const exportWithdrawal = async <T>(param: any): Promise<IRequestResult<T>> => {
    return sendPost<T>(`${ENV}/excel/export`, { ...param });
};

// 获取图片上传token
export const getUploadToken = async <T>(): Promise<IRequestResult<T>> => {
    return sendGet<T>('https://api.aijijiang.com/qiniu/token');
};

// 达人列表查询
export const queryUserManager = async <T>(param: any): Promise<IRequestResult<T>> => {
    return sendPost<T>(`${ENV}/userManager/list`, { ...param });
};
// 达人详情
export const userManagerDetail = async <T>(param: any): Promise<IRequestResult<T>> => {
    return sendGet<T>(`${ENV}/userManager/detail`, { ...param });
};

// 设置扣单
export const setRadio = async <T>(param: any): Promise<IRequestResult<T>> => {
    return sendPost<T>(`${ENV}/userManager/changeMasterUserRatio`, { ...param });
};

// 登录信息获取
export const login = async <T>(): Promise<IRequestResult<T>> => {
    return sendPost<T>('https://api.aijijiang.com');
};

// https://api.aijijiang.com/manager/enterKeywordIncome
